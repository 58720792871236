import { Providers } from "@microsoft/mgt-element";
import ResourceConfig from "../config/ResourceConfig";

export const SecureFetch = (
  url: string,
  method: "GET" | "POST" | "PUT" | "DELETE",
  scopes: string[],
  body?: any
): Promise<Response> =>
  Providers.globalProvider.getAccessTokenForScopes(...scopes).then((token) =>
    fetch(url, {
      method: method,
      headers: new Headers({ Authorization: `Bearer ${token}` }),
      body: JSON.stringify(body),
    })
  );

const Fetch = {
  get: (url: string, scopes: string[]) => SecureFetch(url, "GET", scopes),
  post: (url: string, body: any, scopes: string[]) =>
    SecureFetch(url, "POST", scopes, body),
  put: (url: string, body: any, scopes: string[]) =>
    SecureFetch(url, "PUT", scopes, body),
  delete: (url: string, scopes: string[]) => SecureFetch(url, "DELETE", scopes),
};

const FunctionAppUrl = ResourceConfig.discoveryFunctions.endpoint;
const FunctionAppScopes = ResourceConfig.discoveryFunctions.scopes;

export const FunctionAppFetch = {
  get: (path: string) =>
    Fetch.get(`${FunctionAppUrl}${path}`, FunctionAppScopes),
  post: (path: string, body: any) =>
    Fetch.post(`${FunctionAppUrl}${path}`, body, FunctionAppScopes),
  put: (path: string, body: any) =>
    Fetch.put(`${FunctionAppUrl}${path}`, body, FunctionAppScopes),
  delete: (path: string) =>
    Fetch.delete(`${FunctionAppUrl}${path}`, FunctionAppScopes),
};

export default Fetch;
