import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";

const ripple = keyframes`
    0% {
        top: 50%;
        left: 50%;
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
    }
`;

const StyleLoader = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  & div {
    position: absolute;
    border: 4px solid #454ade;
    border-radius: 50%;
    animation: ${ripple} 1s ease-out infinite;
  }
  & div:nth-of-type(2) {
    animation-delay: -0.5s;
  }
`;

const RippleLoader = () => (
  <StyleLoader>
    <div></div>
    <div></div>
  </StyleLoader>
);

export default RippleLoader;
