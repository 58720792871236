import {
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Collapse,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import usePrevious from "../../../hooks/usePrevious";
import SearchService from "../../../services/SearchService";
import { DispatchContext, ISearchPageState, StateContext } from "../SearchPage";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SearchIcon from "@mui/icons-material/Search";

export interface IAttributeFilter {
  title: string;
  searchKey: string;
  filterKey: string;
  displayAll?: boolean;
}

const AttributeFilter = ({
  title,
  searchKey,
  filterKey,
  displayAll,
}: IAttributeFilter) => {
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [options, setOptions] = useState<string[]>();
  const [loading, setLoading] = useState(false);
  const prevSearchValue = usePrevious(searchValue);
  const state = useContext<ISearchPageState>(StateContext);
  const dispatch = useContext(DispatchContext);
  useEffect(() => {
    if (displayAll) {
      if (!options) {
        SearchService.SearchDatasets({
          facets: [searchKey],
          term: "",
          products: [],
          tables: [],
          tags: [],
          columns: [],
          pageSize: 1,
          pageIndex: 0,
        }).then((response) => {
          if (response.facets) {
            const facet = response.facets[searchKey];
            facet?.sort();
            setOptions(facet || []);
          } else {
            setOptions([]);
          }
        });
      }
    } else {
      if (searchValue.length > 0 && searchValue !== prevSearchValue) {
        var id = setTimeout(() => {
          setLoading(true);
          SearchService.AutocompleteSearchField({
            autocompleteTerm: searchValue,
            field: searchKey,
            size: 25,
            term: state.search,
            products: state.filters["productName"],
            tables: state.filters["table"],
            tags: state.filters["tag"],
            columns: state.filters["column"],
          })
            .then((json) => setOptions(json))
            .finally(() => setLoading(false));
        }, 500);
        return () => clearTimeout(id);
      } else if (searchValue.length === 0 && options?.length !== 0) {
        setOptions([]);
      }
    }
  }, [
    searchValue,
    searchKey,
    state.filters,
    state.search,
    prevSearchValue,
    displayAll,
    options,
  ]);

  useEffect(() => {
    if (!open) setSearchValue("");
  }, [open]);

  const filterState = state.filters[filterKey] || [];
  const displayOptions = displayAll
    ? options?.filter((opt) =>
        opt.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
      )
    : options;
  return (
    <Card square elevation={4}>
      <CardHeader
        onClick={() => setOpen(!open)}
        title={
          <Typography
            // variant="h6"
            style={{
              textTransform: "uppercase",
              color: "#FFFFFF",
            }}
          >
            {title}
          </Typography>
        }
        action={
          <IconButton>
            {open ? (
              <KeyboardArrowDownIcon style={{ color: "#FFFFFF" }} />
            ) : (
              <KeyboardArrowRightIcon style={{ color: "#FFFFFF" }} />
            )}
          </IconButton>
        }
        style={{
          background: "#000000",
          padding: "5px 15px 5px 15px",
          cursor: "pointer",
        }}
      />
      <CardContent style={{ padding: "0px 15px" }}>
        {filterState && filterState.length !== 0 && (
          <div style={{ padding: "10px 0px" }}>
            {filterState.map((x, i) => (
              <Chip
                key={i}
                label={x}
                variant="outlined"
                style={{ margin: "0px 4px 4px 0px" }}
                onDelete={() =>
                  dispatch({
                    type: "filterUpdate",
                    key: filterKey,
                    values: filterState.filter((y) => y !== x),
                  })
                }
              />
            ))}
          </div>
        )}
        <Collapse in={open}>
          <div style={{ padding: "10px 0px" }}>
            <TextField
              fullWidth
              placeholder={`${title.toLowerCase()} search`}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: loading ? (
                  <InputAdornment position="end">
                    <CircularProgress size={15} />
                  </InputAdornment>
                ) : undefined,
              }}
            />
            {displayOptions?.length !== 0 && (
              <List sx={{ maxHeight: "350px", overflowY: "scroll" }}>
                {displayOptions?.map((text, i) => (
                  <ListItem key={i} disablePadding>
                    <ListItemButton
                      onClick={() =>
                        dispatch({
                          type: "filterUpdate",
                          key: filterKey,
                          values: [
                            ...filterState.filter((x) => x !== text),
                            text,
                          ],
                        })
                      }
                    >
                      <ListItemText primary={text} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            )}
          </div>
        </Collapse>
      </CardContent>
    </Card>
  );
};

export default AttributeFilter;
