import { Button, ButtonGroup, Dialog, Typography } from "@mui/material";
import { Stack } from "@mui/material";

interface IDeleteConfirmation {
  onDelete: () => void;
  onClose: () => void;
  open: boolean;
  message: string;
}

const DeleteConfirmation = ({
  onDelete,
  onClose,
  open,
  message,
}: IDeleteConfirmation) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <Stack padding={5} justifyContent="center" alignItems="center">
        <Typography>{message}</Typography>
        <ButtonGroup>
          <Button variant="contained" color="primary" onClick={onDelete}>
            Yes
          </Button>
          <Button variant="contained" color="secondary" onClick={onClose}>
            No
          </Button>
        </ButtonGroup>
      </Stack>
    </Dialog>
  );
};

export default DeleteConfirmation;
