import { Grid } from "@mui/material";
import ProductCard from "../../components/Product/ProductCard";
import useAuthenticatedUser from "../../hooks/useAuthenticatedUser";

const ProductCreatePage = () => {
  const user = useAuthenticatedUser();
  return (
    <Grid container>
      <Grid item xs={12}>
        <ProductCard
          editable
          description=""
          name=""
          id={0}
          owners={[user?.username || ""]}
        />
      </Grid>
    </Grid>
  );
};

export default ProductCreatePage;
