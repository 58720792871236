import { Card, CardProps } from "@mui/material";
import { useCallback, useState } from "react";

interface IHoverCard {
  children: React.ReactNode[] | React.ReactNode;
  noHoverElevation?: number;
  hoverElevation?: number;
}

const HoverCard = ({
  children,
  noHoverElevation,
  hoverElevation,
  ...props
}: IHoverCard & CardProps) => {
  const [isHover, setIsHover] = useState(false);
  const onMouseEnter = useCallback(() => setIsHover(true), []);
  const onMouseLeave = useCallback(() => setIsHover(false), []);
  return (
    <Card
      {...props}
      elevation={isHover ? hoverElevation ?? 8 : noHoverElevation ?? 3}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </Card>
  );
};

export default HoverCard;
