import RippleLoader from "./RippleLoader";

const Loader = () => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      height: "80vh",
    }}
  >
    <div style={{ height: "200px", width: "200px" }}>
      <RippleLoader />
    </div>
  </div>
);

export default Loader;
