import { FunctionAppFetch } from "./Fetch";
import { IDataset } from "./interfaces/IDataset";
import { IInstitution } from "./interfaces/IInstitution";



export interface IFilters {
  term?: string;
  products?: string[];
  tables?: string[];
  tags?: string[];
  columns?: string[];
}

export interface ISearchRequest extends IFilters {
  pageIndex: number;
  pageSize: number;
  sortBy?: string;
  facets?: string[];
}

export interface IPagedResult<T> {
  pageIndex: number;
  totalResults: number;
  results: T[];
  facets?: { [key: string]: string[] };
}

const SearchDatasets = (
  request: ISearchRequest
): Promise<IPagedResult<IDataset>> =>
  FunctionAppFetch.post("/api/table:search", request).then((response) =>
    response.json()
  );

export interface IAutocompleteRequest extends IFilters {
  autocompleteTerm: string;
  field: string;
  size: number;
}

const AutocompleteSearchField = (
  request: IAutocompleteRequest
): Promise<string[]> =>
  FunctionAppFetch.post("/api/table:autocomplete", request).then((response) =>
    response.json()
  );

const Get = (id: string): Promise<IDataset> =>
  FunctionAppFetch.get(`/api/table/${id}`).then((response) => response.json());

const GetInstitutions = (id: string): Promise<IInstitution[]> =>
  FunctionAppFetch.get(
    `/api/table/${id.replaceAll("_", "--")}/institutions`
  ).then((response) => response.json());

const SearchService = {
  SearchDatasets,
  AutocompleteSearchField,
  Get,
  GetInstitutions,
};

export default SearchService;
