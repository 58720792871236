import { FunctionAppFetch } from "./Fetch";
import { IColumn } from "./interfaces/IColumn";
import { IDataset } from "./interfaces/IDataset";
import { IDatasetInstitution } from "./interfaces/IDatasetInstitution";

const getProductDatasets = (productId: number): Promise<IDataset[]> =>
  FunctionAppFetch.get(`/api/products/${productId}/datasets`).then((response) =>
    response.json()
  );

const getDataset = (id: number): Promise<IDataset> =>
  FunctionAppFetch.get(`/api/datasets/${id}`).then((response) =>
    response.json()
  );

const postDataset = (dataset: IDataset): Promise<IDataset> =>
  FunctionAppFetch.post("/api/datasets", dataset).then((response) =>
    response.json()
  );

const putDataset = (dataset: IDataset): Promise<IDataset> =>
  FunctionAppFetch.put(`/api/datasets/${dataset.id}`, dataset).then(
    (response) => response.json()
  );

const addColumn = (column: IColumn): Promise<IColumn> =>
  FunctionAppFetch.post(
    `/api/datasets/${column.datasetId}/columns`,
    column
  ).then((response) => response.json());

const updateColumn = (column: IColumn) =>
  FunctionAppFetch.put(
    `/api/datasets/${column.datasetId}/columns/${column.id}`,
    column
  ).then((response) => response.json());

const isAuthorized = (datasetId: number): Promise<boolean> =>
  FunctionAppFetch.get(`/api/datasets/${datasetId}/authorized`).then(
    (response) => response.json()
  );

const deleteDataset = (datasetId: number): Promise<boolean> =>
  FunctionAppFetch.delete(`/api/datasets/${datasetId}`).then(
    (response) => response.status === 200
  );

const deleteColumn = (datasetId: number, columnId: number): Promise<boolean> =>
  FunctionAppFetch.delete(
    `/api/datasets/${datasetId}/columns/${columnId}`
  ).then((response) => response.status === 200);

const getInstitutions = (datasetId: number): Promise<IDatasetInstitution[]> =>
  FunctionAppFetch.get(`/api/datasets/${datasetId}/institutions`).then(
    (response) => response.json()
  );

const checkDatasetName = (productId: number, name: string): Promise<boolean> =>
  FunctionAppFetch.get(
    `/api/products/${productId}/datasets:nameCheck?name=${name}`
  ).then((response) => response.json());

const DatasetService = {
  getProductDatasets,
  getDataset,
  postDataset,
  putDataset,
  addColumn,
  updateColumn,
  isAuthorized,
  deleteDataset,
  deleteColumn,
  getInstitutions,
  checkDatasetName,
};

export default DatasetService;
