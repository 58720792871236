import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { Pagination, Stack, TableCell, TextField } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import SortIcon from "@mui/icons-material/Sort";

interface IDataTable<T> {
  title: string;
  header: { name: string; field?: string; sortable?: boolean }[];
  data: T[];
  mapper: (row: T) => JSX.Element;
  searchFunc: (term: string, data: T[]) => T[];
  addFunc?: () => void;
  pageSize?: number;
}

function DataTable<T>({
  title,
  header,
  data,
  mapper,
  searchFunc,
  addFunc,
  pageSize = 10,
}: IDataTable<T>) {
  const [searchTerm, setSearchTerm] = useState("");
  const [sortByIndex, setSortByIndex] = useState(0);
  const [page, setPage] = useState(1);

  const displayable = useMemo(
    () =>
      [...(searchTerm ? searchFunc(searchTerm, data) : data)].sort(
        (a: any, b: any) => {
          return a[header[sortByIndex].field ?? ""] <=
            b[header[sortByIndex].field ?? ""]
            ? -1
            : 1;
        }
      ),
    [searchTerm, data, searchFunc, sortByIndex, header]
  );

  useEffect(() => {
    setPage(1);
  }, [searchTerm]);

  const startIndex = (page - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const pageDisplay = displayable.slice(
    startIndex,
    endIndex < displayable.length ? endIndex : displayable.length
  );

  return (
    <Stack spacing={2}>
      <Typography variant="h5">{title}</Typography>
      <Box display="flex" width={"40%"}>
        <Box flexGrow={1}>
          <TextField
            placeholder="Search"
            size="small"
            fullWidth
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        {addFunc && (
          <Tooltip title="Add">
            <Button onClick={addFunc}>
              <AddIcon />
            </Button>
          </Tooltip>
        )}
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            {header.map((x, i) => (
              <TableCell key={i}>
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography>{x.name}</Typography>
                  {x.sortable && (
                    <IconButton
                      onClick={() => setSortByIndex(i)}
                      aria-label={`Sort By ${x.name}`}
                      style={
                        sortByIndex === i
                          ? { border: "1px solid #000" }
                          : undefined
                      }
                    >
                      <SortIcon
                        htmlColor={sortByIndex === i ? "#000" : "#8f8f8f"}
                      />
                    </IconButton>
                  )}
                </Stack>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {pageDisplay.length !== 0 && (
          <TableBody>{pageDisplay.map((x, i) => mapper(x))}</TableBody>
        )}
      </Table>
      {pageDisplay.length === 0 && (
        <Box
          height={150}
          justifyContent="center"
          alignItems="center"
          display="flex"
        >
          <Typography>No Data</Typography>
        </Box>
      )}
      <Pagination
        count={Math.ceil(displayable.length / pageSize)}
        page={page}
        onChange={(e: React.ChangeEvent<unknown>, v: number) => setPage(v)}
      />
    </Stack>
  );
}

export default DataTable;
