export interface IProtectedResources {
  [key: string]: IProtectedResource;
}

export interface IProtectedResource {
  endpoint: string;
  scopes: string[];
}

const ResourceConfig = {
  graphMe: {
    endpoint: "https://graph.microsoft.com/v1.0/me",
    scopes: ["User.Read"],
  },
  graphPeople: {
    endpoint: "https://graph.microsoft.com/v1.0/me/people",
    scopes: ["People.Read"],
  },
  graphUsers: {
    endpoint: "https://graph.microsoft.com/v1.0/users",
    scopes: ["User.ReadBasic.All"],
  },
  discoveryFunctions: {
    endpoint: process.env.REACT_APP_DATA_DISCOVERY_FUNCTIONS_URL as string,
    scopes: [
      process.env.REACT_APP_DATA_DISCOVERY_FUNCTIONS_URL_SCOPES,
    ] as string[],
  },
} as IProtectedResources;

export default ResourceConfig;
