import { Grid, Typography } from "@mui/material";
import HoverCard from "../../components/HoverCard";

const InstructionPage = () => {
  const v2Scheam = {
    $schema: "http://json-schema.org/draft-04/schema#",
    type: "object",
    properties: {
      schemaVersion: {
        type: "integer",
      },
      metadataVersion: {
        type: "integer",
      },
      product: {
        type: "string",
      },
      productDescription: {
        type: "string",
      },
      owners: {
        type: "array",
        items: [
          {
            type: "string",
          },
        ],
      },
      datasets: {
        type: "array",
        items: [
          {
            type: "object",
            properties: {
              name: {
                type: "string",
              },
              description: {
                type: "string",
              },
              locationPath: {
                type: "string",
              },
              fileType: {
                type: "string",
              },
              encodingType: {
                type: "string",
              },
              updateFrequency: {
                type: "string",
              },
              hasHeader: {
                type: "boolean",
              },
              tags: {
                type: "array",
                items: [
                  {
                    type: "string",
                  },
                ],
              },
              owners: {
                type: "array",
                items: [
                  {
                    type: "string",
                  },
                ],
              },
              columns: {
                type: "array",
                items: [
                  {
                    type: "object",
                    properties: {
                      name: {
                        type: "string",
                      },
                      description: {
                        type: "string",
                      },
                      type: {
                        type: "string",
                      },
                      tags: {
                        type: "array",
                        items: [
                          {
                            type: "string",
                          },
                        ],
                      },
                    },
                    required: ["name", "description", "type", "tags"],
                  },
                ],
              },
            },
            required: [
              "name",
              "description",
              "locationPath",
              "fileType",
              "encodingType",
              "updateFrequency",
              "hasHeader",
              "tags",
              "owners",
              "columns",
            ],
          },
        ],
      },
    },
    required: [
      "schemaVersion",
      "metadataVersion",
      "product",
      "productDescription",
      "owners",
      "datasets",
    ],
  };

  const sampleV2 = {
    schemaVersion: 2,
    metadataVersion: 1,
    product: "Sample Product",
    productDescription: "Sample Product Description",
    owners: ["pice@anthology.com"],
    datasets: [
      {
        name: "Sample Dataset",
        description: "Sample Dataset Description",
        locationPath: "Institution/{institutionId}/sample/data.csv",
        fileType: "csv",
        encodingType: "UTF-8",
        updateFrequency: "Daily",
        hasHeader: true,
        tags: ["Sample"],
        owners: ["akarkal@anthology.com"],
        columns: [
          {
            name: "Id",
            description: "",
            type: "String",
            tags: ["PK"],
          },
          {
            name: "StudentId",
            description: "",
            type: "String",
            tags: ["PII"],
          },
          {
            name: "StudentName",
            description: "",
            type: "String",
            tags: ["PII"],
          },
          {
            name: "StudentMajor",
            description: "",
            type: "String",
            tags: ["Class"],
          },
        ],
      },
    ],
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h3" variantMapping={{ h3: "h1" }}>
          Instructions
        </Typography>
        <Typography variant="h6" variantMapping={{ h6: "h2" }}>
          Externally Manage Datasets
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography>
          The Anthology Data Discovery tool allows for two ways to manage shared
          dataset information. One option is the manually manage your datasets
          within the tool. The other option is to have the dataset externally
          managed via JSON files stored in the legacy Campus Labs Data Lake.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" variantMapping={{ h6: "h3" }}>
          How it Works
        </Typography>
        <Typography>
          Anybody who wants their dataset to be externally managed and displayed
          within the tool can create JSON files that follow the structure of the
          JSON file sample provided below. Once completed the file can simply be
          dropped into the legacy Campus Labs Data Lake in the "/DataDiscovery"
          directory. This directory is recursively scanned daily to pick up any
          modifications to the provided JSON files and ingest them into the
          tool. When creating and managing datasets externally, while the
          recursive nature of the ingestion tool makes it so you can put your
          files at any depth under the "/DataDiscovery" directory. It is
          recommended that you follow the structure of "/DataDiscovery/{"{"}
          Product Name{"}"}/{"{"}Dataset Name{"}"}.json". That way all files
          that pertain to a particular product can be isolated.
        </Typography>
      </Grid>
      <Grid item xs={12} container justifyContent="center">
        <div style={{ width: "100%" }}>
          <Typography variant="h6" variantMapping={{ h6: "h3" }}>
            Json File Sample
          </Typography>
        </div>
        <div style={{ width: "70%" }}>
          <HoverCard>
            <pre style={{ padding: "20px" }}>
              {JSON.stringify(sampleV2, null, 3)}
            </pre>
          </HoverCard>
        </div>
      </Grid>
      <Grid item container xs={12} justifyContent="center">
        <div style={{ width: "100%" }}>
          <Typography variant="h6" variantMapping={{ h6: "h3" }}>
            Json File Schema
          </Typography>
        </div>
        <div style={{ width: "70%" }}>
          <HoverCard>
            <pre style={{ padding: "20px" }}>
              {JSON.stringify(v2Scheam, null, 3)}
            </pre>
          </HoverCard>
        </div>
      </Grid>
    </Grid>
  );
};

export default InstructionPage;
