import {
  Box,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { Link, useLocation, useNavigate } from "react-router-dom";
import remarkGfm from "remark-gfm";
import { IProduct } from "../../services/interfaces/IProduct";
import ContactGroup from "../ContactGroup";
import HoverCard from "../HoverCard";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import InstitutionIcon from "../icons/InstitutionIcon";
import DatasetIcon from "../icons/DatasetIcon";
import ProductService from "../../services/ProductService";
import { useSnackbar } from "notistack";
import DeleteConfirmation from "../DeleteConfirmation";
import { IInstitution } from "../../services/interfaces/IInstitution";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import EditingProductCard from "./EditProductCard";

interface IProductCard {
  editable?: boolean;
}

const ProductCard = (props: IProduct & IProductCard) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [authorized, setAuthorized] = useState(false);
  const [headerHover, setHeaderHover] = useState(false);
  const [isEditing, setIsEditing] = useState(
    () => props.id <= 0 && props.editable
  );
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [institutions, setInstitutions] = useState<IInstitution[]>();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (props.id >= 0 && props.editable) {
      ProductService.isAuthorized(props.id).then((v) => {
        setAuthorized(v);
      });
    } else if (props.id <= 0) {
      setAuthorized(true);
    }
  }, [props.id, props.editable]);

  useEffect(() => {
    ProductService.getProductInstitutions(props.id).then(setInstitutions);
  }, [props.id]);

  const onDelete = () =>
    ProductService.deleteProduct(props.id)
      .then((result) => {
        enqueueSnackbar("Product Deleted", { variant: "success" });
        if (result) navigate(`/products`);
        else {
          enqueueSnackbar("Product Deletion Failed", { variant: "error" });
        }
      })
      .catch(() =>
        enqueueSnackbar("Product Deletion Failed", { variant: "error" })
      );

  if (isEditing)
    return (
      <EditingProductCard
        product={props}
        onDiscard={() => {
          if (props.id > 0) setIsEditing(false);
          else navigate("/products");
        }}
        onSave={(editState) => {
          // check that everything is good
          if (editState.name === "") {
            enqueueSnackbar("Product name needs to be unique.", {
              variant: "error",
            });
          } else {
            (editState.id <= 0
              ? ProductService.postProduct(editState)
              : ProductService.putProduct(editState)
            ).then((product) => {
              enqueueSnackbar("Product Saved!", { variant: "success" });
              const productPath = `/products/${product.id}`;
              if (location.pathname !== productPath) navigate(productPath);
              else navigate(0);
            });
          }
        }}
      />
    );

  const header = (
    <Link
      to={`/products/${props.id}`}
      style={{ textDecoration: "none", color: "#ffffff" }}
    >
      <div style={{ display: "flex", verticalAlign: "middle" }}>
        <Typography
          variant="h6"
          style={{ display: "inline-flex", flexGrow: 1 }}
        >
          {props.name}
        </Typography>
        {headerHover &&
          (authorized ? (
            <>
              <Tooltip title="Edit">
                <IconButton
                  onClick={(e) => {
                    e.preventDefault();
                    setIsEditing(true);
                  }}
                  size="small"
                  style={{ color: "white" }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="delete">
                <IconButton
                  onClick={(e) => {
                    e.preventDefault();
                    setDeleteConfirmationOpen(true);
                  }}
                  color="secondary"
                  size="small"
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </>
          ) : (
            <KeyboardArrowRightIcon
              style={{
                display: "inline-flex",
                verticalAlign: "middle",
                fontSize: "32px",
              }}
            />
          ))}
      </div>
    </Link>
  );

  const description = (
    <ReactMarkdown
      unwrapDisallowed
      disallowedElements={["h1", "h2", "h3", "h4", "h5", "h6"]}
      remarkPlugins={[remarkGfm]}
    >
      {props.description}
    </ReactMarkdown>
  );

  const owners = (
    <>
      <Typography>Owners</Typography>
      <ContactGroup
        emails={props.owners}
        tracker={{ productId: props.id, productName: props.name }}
        cannedMessage={`Hi I'm interested in learning more about your product ${props.name}.`}
      />
    </>
  );

  return (
    <HoverCard square style={{ height: "100%" }}>
      <CardHeader
        title={header}
        style={{ backgroundColor: "#000000", color: "#ffffff",height:'10%' }}
        onMouseOver={() => setHeaderHover(true)}
        onMouseLeave={() => setHeaderHover(false)}
      />
      <CardContent style={{ height: "90%" }}>
        <Stack style={{ height: "100%" }}>
          <Box flexGrow={1}>{description}</Box>
          <Box
            borderTop={"1px solid RGB(211,211,211)"}
            borderBottom={"1px solid RGB(211,211,211)"}
            padding={"5px 0px"}
            margin={"5px 0px"}
          >
            <Grid container>
              <Grid item xs={6}>
                <Tooltip title="No. of Datasets" arrow placement="top">
                  <DatasetIcon fontSize="large" />
                </Tooltip>
                <Typography>{props.datasets?.length ?? "NA"}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Tooltip title="No. of Institutions" arrow placement="top">
                  <InstitutionIcon fontSize="large" />
                </Tooltip>
                <Typography>{institutions?.length ?? "NA"}</Typography>
              </Grid>
            </Grid>
          </Box>
          <Box>{owners}</Box>
        </Stack>
      </CardContent>
      <DeleteConfirmation
        open={deleteConfirmationOpen}
        onClose={() => setDeleteConfirmationOpen(false)}
        onDelete={onDelete}
        message="Are you sure you want to delete this product?"
      />
    </HoverCard>
  );
};

export default ProductCard;
