import { Button, Grid, InputAdornment } from "@mui/material";
import { useEffect, useState } from "react";
import { IProduct } from "../../services/interfaces/IProduct";
import ProductService from "../../services/ProductService";
import ProductCard from "../../components/Product/ProductCard";
import { TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import Loader from "../../components/Loader";

const ProductsPage = () => {
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState<IProduct[]>();
  useEffect(() => {
    ProductService.getProducts()
      .then((products) => {
        products.sort((a, b) => a.name.localeCompare(b.name));
        return products;
      })
      .then(setProducts)
      .finally(() => setLoading(false));
  }, []);

  const displayProducts = search
    ? products?.filter((x) => x.name.toLowerCase().includes(search))
    : products;

  if (loading) {
    return <Loader />;
  }

  return (
    <Grid container spacing={3} style={{ margin: "0px 55px" }}>
      <Grid
        item
        container
        md={12}
        justifyContent="center"
        style={{ display: "flex" }}
      >
        <div style={{ width: "60%", display: "inline-flex" }}>
          <TextField
            fullWidth
            placeholder="Search Products"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
          <Link
            style={{ display: "inline-flex", marginLeft: "10px" }}
            to="/products/new"
          >
            <Button>
              <AddIcon />
            </Button>
          </Link>
        </div>
      </Grid>
      {displayProducts &&
        displayProducts.map((product) => (
          <Grid key={product.id} item md={4}>
            <ProductCard {...product} />
          </Grid>
        ))}
    </Grid>
  );
};

export default ProductsPage;
