import { Avatar, darken, Grid, styled, Typography } from "@mui/material";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { Stack } from "@mui/material";
import { MouseEventHandler, useCallback, useEffect, useState } from "react";
import UserService, { IPerson } from "../services/UserService";
import HoverCard from "./HoverCard";

const StyledDiv = styled("div")`
  display: inline-block;
  cursor: pointer;
  margin: 5px;
`;

export interface IContactPerson {
  email: string;
  cannedMessage?: string;
  tracker?: any;
}

const ContactPerson = ({ email, cannedMessage, tracker }: IContactPerson) => {
  const appInsights = useAppInsightsContext();
  const [person, setPerson] = useState<IPerson>();
  const [imageBlob, setImageBlob] = useState<string>();
  useEffect(() => {
    UserService.GetPerson(email).then(setPerson);
    UserService.GetUserPhoto(email).then(setImageBlob);
  }, [email]);

  const stringToColor = useCallback((string: string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */

    return darken(color, 0.3);
  }, []);

  return (
    <StyledDiv
      role={"button"}
      onClick={(e) => {
        e.preventDefault();
        if (tracker) {
          appInsights.trackEvent(
            { name: "ContactClicked" },
            { contactClicked: email, ...tracker }
          );
        }
        window.open(
          `https://teams.microsoft.com/l/chat/0/0?users=${email}`, //&message=${cannedMessage} :( but teams has a bug with the pre-canned message
          "_blank"
        );
      }}
    >
      <HoverCard noHoverElevation={0} hoverElevation={3}>
        <Stack direction={"row"} spacing={1} style={{ padding: "5px" }}>
          <Avatar
            alt={person?.displayName || email}
            src={imageBlob}
            style={{
              width: 56,
              height: 56,
              backgroundColor: stringToColor(
                person?.displayName || email || ""
              ),
            }}
          >
            {(person?.displayName || email)
              .split(" ")
              .filter((x) => x.length > 0)
              .map((x) => x[0])
              .join("")}
          </Avatar>
          <Stack justifyContent={"center"}>
            <Typography>{person?.displayName || email}</Typography>
            <Typography variant="caption">{person?.jobTitle}</Typography>
          </Stack>
        </Stack>
      </HoverCard>
    </StyledDiv>
  );
};

export interface IContactGroup {
  emails: string[];
  cannedMessage?: string;
  tracker?: any;
}

const ContactGroup = ({ emails, cannedMessage, tracker }: IContactGroup) => {
  return (
    <div>
      {emails &&
        emails
          .filter((x) => typeof x === "string")
          .map((x) => x.trim())
          .filter((x) => x.length > 0)
          .map((email, i) => (
            <ContactPerson
              key={i}
              email={email}
              cannedMessage={cannedMessage}
              tracker={tracker}
            />
          ))}
    </div>
  );
};

export default ContactGroup;
