import { Route, Routes, useParams } from "react-router-dom";
import DatasetCreatePage from "./DatasetCreatePage";
import DatasetPage from "./DatasetPage";

const DatasetRoutes = () => {
  const params = useParams() as any;
  const productId = params.productId as number;
  return (
    <Routes>
      <Route path="new" element={<DatasetCreatePage productId={productId} />} />
      <Route path=":id" element={<DatasetPage productId={productId} />} />
    </Routes>
  );
};

export default DatasetRoutes;
