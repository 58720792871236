import DatasetCard from "../../../components/Dataset/DatasetCard";
import { IDataset } from "../../../services/interfaces/IDataset";

interface IResultCard {
  dataset: IDataset;
}

const ResultCard = ({ dataset }: IResultCard) => {
  return <DatasetCard dataset={dataset} />;
};

export default ResultCard;
