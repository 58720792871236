import { Grid } from "@mui/material";
import DatasetCard from "../../components/Dataset/DatasetCard";
import useAuthenticatedUser from "../../hooks/useAuthenticatedUser";

export interface IDatasetCreatePage {
  productId: number;
}

const DatasetCreatePage = ({ productId }: IDatasetCreatePage) => {
  const user = useAuthenticatedUser();
  return (
    <Grid container direction="column">
      <Grid item xs={12}>
        <DatasetCard
          editable
          dataset={{
            productId: productId,
            productName: "",
            columns: [],
            encodingType: "",
            fileType: "",
            institutionIds: [],
            locationPath: "",
            tags: [],
            updateFrequency: "",
            description: "",
            name: "",
            id: 0,
            owners: [user?.username || ""],
          }}
        />
      </Grid>
    </Grid>
  );
};

export default DatasetCreatePage;
