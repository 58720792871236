import ResourceConfig from "../config/ResourceConfig";
import { SecureFetch } from "./Fetch";
export interface IPerson {
  displayName: string;
  givenName: string;
  jobTitle?: string;
  mail?: string;
  mobilePhone?: string;
  officeLocation?: string;
  preferredLanguage?: string;
  userPrincipalName: string;
  id: string;
}

const activeGetPersonRequests = new Map<string, Promise<IPerson>>();

const GetPersonPeopleQuery = (id: string): Promise<IPerson> =>
  SecureFetch(
    `${ResourceConfig.graphPeople.endpoint}?$search="${id}"&$filter=personType/class eq 'Person'&$top=1`,
    "GET",
    ResourceConfig.graphPeople.scopes
  )
    .then((response) => response.json())
    .then((json) => {
      if (json.value && json.value.length) {
        const value = json.value[0];
        return {
          id: value.id,
          displayName: value.displayName,
        } as IPerson;
      } else {
        throw new Error("No Person found");
      }
    });

const GetPerson = (id: string): Promise<IPerson> => {
  const key = `person:${id}`;
  const cachedItem = localStorage.getItem(key);
  if (cachedItem) {
    return Promise.resolve(JSON.parse(cachedItem) as IPerson);
  } else if (activeGetPersonRequests.has(key)) {
    return activeGetPersonRequests.get(key) as Promise<IPerson>;
  } else {
    const ret = SecureFetch(
      `${ResourceConfig.graphUsers.endpoint}/${id}`,
      "GET",
      ResourceConfig.graphUsers.scopes
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          return GetPersonPeopleQuery(id);
        }
      })
      .then((json) => {
        localStorage.setItem(key, JSON.stringify(json));
        setTimeout(() => activeGetPersonRequests.delete(key), 100);
        return json;
      })
      .catch(() => console.log(`Failed to find user for: ${id}`));
    activeGetPersonRequests.set(key, ret);
    return ret;
  }
};

const activePersonPhotoRequests = new Map<string, Promise<string>>();

const GetPersonPhoto = (id: string): Promise<string> => {
  const key = `person:photo:${id}`;
  // const cachedItem = localStorage.getItem(key);
  // if (cachedItem) {
  //   return Promise.resolve(cachedItem);
  // } else
  if (activePersonPhotoRequests.has(key)) {
    return activePersonPhotoRequests.get(key) as Promise<string>;
  } else {
    const ret = SecureFetch(
      `${ResourceConfig.graphUsers.endpoint}/${id}/photo/$value`,
      "GET",
      ResourceConfig.graphUsers.scopes
    )
      .then((response) => response.blob())
      .then((blob) => URL.createObjectURL(blob));
    // .then((x) => {
    //   localStorage.setItem(key, x);
    //   setTimeout(() => activePersonPhotoRequests.delete(key), 100);
    //   return x;
    // });
    activePersonPhotoRequests.set(key, ret);
    return ret;
  }
};

const UserService = {
  GetPerson,
  GetUserPhoto: GetPersonPhoto,
};

export default UserService;
