import TopBar from "./components/TopBar";
import { Route, Routes, useLocation } from "react-router-dom";
import SearchPage from "./pages/search/SearchPage";
import styled from "@emotion/styled";
import useAuthenticated from "./hooks/useAuthenticated";
import { useEffect } from "react";
import ProductRoutes from "./pages/products/ProductRoutes";
import { Providers, ProviderState } from "@microsoft/mgt-element";
import { Msal2Provider } from "@microsoft/mgt-msal2-provider";
import InstructionPage from "./pages/instructions/InstructionPage";
import AppSpeedDial from "./components/AppSpeedDial";

const Container = styled.div`
  margin: 0px 24px;
  display: flex;
`;

const NotAuthenticated = () => <div>We need to know who you are</div>;

function App() {
  const location = useLocation();
  const isAuthenticated = useAuthenticated();

  // Setting up appcues to trigger on page changes
  useEffect(() => {
    if (
      // @ts-ignore
      window.Appcues
    ) {
      if (
        Providers.globalProvider.state === ProviderState.SignedIn &&
        Providers.globalProvider.getAllAccounts
      ) {
        const accounts = Providers.globalProvider.getAllAccounts();
        // @ts-ignore
        window.Appcues.identify(accounts[0].username);
      } else {
        // @ts-ignore
        window.Appcues.page();
      }
    }
  }, [location]);

  useEffect(() => {
    (async () => {
      const provider = Providers.globalProvider as Msal2Provider;
      if (provider) {
        if (provider.state === ProviderState.SignedOut) {
          await provider.trySilentSignIn();
          // if (provider.state === ProviderState.SignedOut) {
          //   await provider.login();
          // }
        }
      }
    })();
  }, []);

  return (
    <div>
      <TopBar />
      <Container>
        {isAuthenticated ? (
          <Routes>
            <Route path="/products/*" element={<ProductRoutes />} />
            <Route path="/instructions" element={<InstructionPage />} />
            <Route path="/search" element={<SearchPage />} />
            <Route path="/" element={<SearchPage />} />
          </Routes>
        ) : (
          <NotAuthenticated />
        )}
        <AppSpeedDial />
      </Container>
    </div>
  );
}

export default App;
