import { FunctionAppFetch } from "./Fetch";

const sendFeedback = (message: string): Promise<boolean> =>
  FunctionAppFetch.post("/api/feedback", { message })
    .then((x) => x.status === 200)
    .catch((e) => false);

const FeedbackService = {
  sendFeedback,
};

export default FeedbackService;
