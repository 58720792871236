import {
  Button,
  ButtonGroup,
  CardContent,
  Dialog,
  Link,
  TableRow,
} from "@mui/material";
import {
  Chip,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  TableCell,
  TextField,
} from "@mui/material";
// import { DataGrid } from "@mui/x-data-grid";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import DatasetCard from "../../components/Dataset/DatasetCard";
import HoverCard from "../../components/HoverCard";
import Loader from "../../components/Loader";
import DatasetService from "../../services/DatasetService";
import { IDataset } from "../../services/interfaces/IDataset";
import { IColumn } from "../../services/interfaces/IColumn";
import DeleteIcon from "@mui/icons-material/Delete";
import { IDatasetInstitution } from "../../services/interfaces/IDatasetInstitution";
import ValidatedTextField from "../../components/ValidatedTextField";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import DataTable from "../../components/DataTable";
import { IProduct } from "../../services/interfaces/IProduct";
import ProductService from "../../services/ProductService";

interface IInstitutionCard {
  institutions?: IDatasetInstitution[];
}

const InstitutionCard = ({ institutions = [] }: IInstitutionCard) => {
  const insts = useMemo(
    () =>
      institutions.map((x) => ({
        id: x.institution.id,
        name: x.institution.name,
        lastUpdated: x.lastUpdated,
      })),
    [institutions]
  );
  return (
    <HoverCard square>
      <CardContent>
        <DataTable
          header={[
            { name: "Id", field: "id", sortable: true },
            { name: "Name", field: "name", sortable: true },
            { name: "Last Updated On", field: "lastUpdated", sortable: true },
          ]}
          data={insts}
          title="Institutions"
          mapper={(row) => (
            <TableRow key={row.id}>
              <TableCell>{row.id}</TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.lastUpdated}</TableCell>
            </TableRow>
          )}
          searchFunc={(term, data) =>
            data.filter((x) =>
              x.name.toLowerCase().includes(term.toLowerCase())
            )
          }
        />
      </CardContent>
    </HoverCard>
  );
};

interface IColumnCard {
  authorized: boolean;
}

const defaultColumn = (id: number) => ({
  id: 0,
  datasetId: id,
  name: "",
  type: "",
  description: "",
  tags: [],
  fileIndex: 0,
});

const ColumnCard = (props: IDataset & IColumnCard) => {
  const navigate = useNavigate();
  const [columnModalOpen, setColumnModalOpen] = useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [focusedColumn, setFocusedColumn] = useState<IColumn>();

  const [column, setColumn] = useState<IColumn>(defaultColumn(props.id));

  const onDelete = useCallback(
    (column: IColumn) =>
      DatasetService.deleteColumn(column.datasetId, column.id).then(() =>
        navigate(0)
      ),
    [navigate]
  );

  const onSave = useCallback(() => {
    return column.id <= 0
      ? () => DatasetService.addColumn(column).then((col) => navigate(0))
      : () => DatasetService.updateColumn(column).then((col) => navigate(0));
  }, [column, navigate]);

  return (
    <HoverCard square>
      <CardContent>
        <DataTable
          header={[
            ...[
              { name: "Index", field: "fileIndex", sortable: true },
              { name: "Name", field: "name", sortable: true },
              { name: "Type", field: "type", sortable: true },
              { name: "Tags", field: "tags" },
            ],
            ...(props.authorized ? [{ name: "Actions" }] : []),
          ]}
          data={props.columns}
          title="Columns"
          addFunc={
            props.authorized
              ? () => {
                  setColumn(defaultColumn(props.id));
                  setColumnModalOpen(true);
                }
              : undefined
          }
          mapper={(row) => (
            <TableRow key={row.id}>
              <TableCell>{row.fileIndex}</TableCell>
              <TableCell>
                {props.authorized ? (
                  <Link
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setColumn(row);
                      setColumnModalOpen(true);
                    }}
                  >
                    {row.name}
                  </Link>
                ) : (
                  row.name
                )}
              </TableCell>
              <TableCell>{row.type}</TableCell>
              <TableCell>
                {row.tags.map((x, i) => (
                  <Chip
                    key={i}
                    variant="outlined"
                    label={x}
                    style={{ marginRight: "2px" }}
                  />
                ))}
              </TableCell>
              {props.authorized && (
                <TableCell>
                  <IconButton
                    onClick={() => {
                      setFocusedColumn(row);
                      setDeleteConfirmationOpen(true);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              )}
            </TableRow>
          )}
          searchFunc={(term, data) =>
            data.filter(
              (x) =>
                x.name.toLowerCase().includes(term.toLowerCase()) ||
                x.type.toLowerCase().includes(term.toLowerCase())
            )
          }
        />
      </CardContent>
      <Dialog
        open={columnModalOpen}
        onClose={() => {
          setColumn(defaultColumn(props.id));
          setColumnModalOpen(false);
        }}
        maxWidth={"sm"}
        fullWidth
      >
        <DialogTitle>
          {column.id > 0 ? "Edit Column" : "Add Column"}
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <ValidatedTextField
              fullWidth
              label="Column Name"
              value={column?.name}
              onChange={(e) => setColumn({ ...column, name: e.target.value })}
              required
              isError={(value) => {
                if (value.trim() === "") return "Column name cannot be empty";
              }}
            />

            <ValidatedTextField
              fullWidth
              label="Column Type"
              value={column?.type}
              onChange={(e) => setColumn({ ...column, type: e.target.value })}
              required
              isError={(value) => {
                if (value.trim() === "") return "Column type cannot be empty";
              }}
            />

            <TextField
              fullWidth
              label="Column Tags"
              helperText="Comma separated list of column tags."
              value={column?.tags.join(",")}
              onChange={(e) =>
                setColumn({ ...column, tags: e.target.value.split(",") })
              }
            />
            <div style={{ width: "100%" }}>
              <ButtonGroup style={{ float: "right" }}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    setColumn(defaultColumn(props.id));
                    setColumnModalOpen(false);
                  }}
                >
                  Discard
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    onSave();
                    setColumnModalOpen(false);
                  }}
                  style={{ float: "right" }}
                >
                  Save
                </Button>
              </ButtonGroup>
            </div>
          </Stack>
        </DialogContent>
      </Dialog>
      <DeleteConfirmation
        onDelete={() => {
          if (focusedColumn) onDelete(focusedColumn);
        }}
        open={deleteConfirmationOpen}
        onClose={() => {
          setDeleteConfirmationOpen(false);
          setFocusedColumn(undefined);
        }}
        message="Are you sure you want to delete this column?"
      />
    </HoverCard>
  );
};

interface IDatasetPage {
  productId: number;
}

const DatasetPage = ({ productId }: IDatasetPage) => {
  const params = useParams();
  const location = useLocation();
  const [dataset, setDataset] = useState<IDataset>(location.state as IDataset);
  const [product, setProduct] = useState<IProduct>();
  const [institutions, setInstitutions] = useState<IDatasetInstitution[]>();
  const [authorized, setAuthorized] = useState(false);

  useEffect(() => {
    const id = parseInt(params?.id ?? "0");
    if (id > 0) {
      DatasetService.isAuthorized(id).then(setAuthorized);
    } else {
      setAuthorized(true);
    }
  }, [params]);

  useEffect(() => {
    if (!dataset) {
      const id = parseInt(params?.id ?? "0");
      DatasetService.getDataset(id).then(setDataset);
    }
  }, [params, dataset]);

  useEffect(() => {
    const id = parseInt(params?.id ?? "0");
    DatasetService.getInstitutions(id).then(setInstitutions);
  }, [params]);

  useEffect(() => {
    if (dataset) {
      ProductService.getProduct(dataset.productId).then(setProduct);
    }
  }, [dataset]);

  if (!dataset && !product) {
    return <Loader />;
  }

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <DatasetCard
          dataset={dataset}
          productOwners={product?.owners}
          expanded
          editable
        />
      </Grid>
      <Grid item>
        <ColumnCard {...dataset} authorized={authorized} />
      </Grid>
      <Grid item>
        <InstitutionCard institutions={institutions} />
      </Grid>
    </Grid>
  );
};

export default DatasetPage;
