import {
  Button,
  ButtonGroup,
  CircularProgress,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import Colors from "../../config/Colors";
import RegexConfig from "../../config/RegexConfig";
import DatasetService from "../../services/DatasetService";
import { IDataset } from "../../services/interfaces/IDataset";
import HoverCard from "../HoverCard";
import ValidatedTextField from "../ValidatedTextField";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";

interface IEditDatasetCard {
  dataset: IDataset;
  onDiscard?: () => void;
  onSave?: (editState: IDataset) => void;
}

const EditDatasetCard = ({ dataset, onDiscard, onSave }: IEditDatasetCard) => {
  const [editState, setEditState] = useState(dataset);
  const [isNameOkay, setIsNameOkay] = useState(true);
  const [checkingName, setCheckingName] = useState(false);

  useEffect(() => {
    if (editState.name === "") setIsNameOkay(false);
    else if (editState.name === dataset.name) setIsNameOkay(true);
    else {
      setCheckingName(true);
      const tid = setTimeout(() => {
        DatasetService.checkDatasetName(dataset.productId, editState.name)
          .then(setIsNameOkay)
          .finally(() => setCheckingName(false));
      }, 500);
      return () => clearTimeout(tid);
    }
  }, [editState.name, dataset.name, dataset.productId]);

  return (
    <HoverCard>
      <Stack padding={3} spacing={3}>
        <TextField
          label="Dataset Name"
          value={editState.name}
          onChange={(e) => setEditState({ ...editState, name: e.target.value })}
          required
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {checkingName ? (
                  <CircularProgress size={15} />
                ) : isNameOkay ? (
                  <CheckIcon htmlColor={Colors.Green} />
                ) : (
                  <Tooltip title="Name must be set and unique.">
                    <ErrorIcon htmlColor={Colors.Red} />
                  </Tooltip>
                )}
              </InputAdornment>
            ),
          }}
        />
        <ValidatedTextField
          label="Dataset Description"
          value={editState.description}
          onChange={(e) =>
            setEditState({ ...editState, description: e.target.value })
          }
          multiline
          rows={15}
          required
          helperText="Markdown syntax can be used to add additional styling."
          isError={(value) => {
            if (value.trim() === "") return "Description cannot be empty";
          }}
        />
        <TextField
          label="Frequency of Updates"
          value={editState.updateFrequency}
          onChange={(e) =>
            setEditState({ ...editState, updateFrequency: e.target.value })
          }
        />
        <TextField
          label="File Type"
          value={editState.fileType}
          onChange={(e) =>
            setEditState({ ...editState, fileType: e.target.value })
          }
        />
        <ValidatedTextField
          label="Location Path"
          value={editState.locationPath}
          onChange={(e) =>
            setEditState({ ...editState, locationPath: e.target.value })
          }
          required
          helperText="Location to dataset stored in the data lake, placeholders are allowed. For example: /Institution/{institutionId}/SampleProduct/{year}/{month}/{day}.csv"
          isError={(value) => {
            if (value.trim() === "") return "Location path cannot be empty";
          }}
        />
        <TextField
          label="Tags"
          value={editState.tags.join(",")}
          onChange={(e) =>
            setEditState({
              ...editState,
              tags: e.target.value.split(","),
            })
          }
          helperText="Comma delimited list of tags."
        />
        <ValidatedTextField
          label="Owners"
          value={editState.owners.join(",")}
          onChange={(e) =>
            setEditState({
              ...editState,
              owners: e.target.value.split(","),
            })
          }
          helperText="Comma delimited list of owner email addresses."
          isError={(value: string) => {
            if (value === "")
              return "At least one owner email address must be provided.";
            const emails = value
              .split(",")
              .map((x) => x.trim())
              .filter((x) => x !== "");
            // all are emails
            if (
              !emails.every((x) =>
                RegexConfig.emailReg.test(x.toLocaleLowerCase())
              )
            ) {
              return "All entries must be valid email addresses.";
            }
          }}
        />
        <div style={{ width: "100%" }}>
          <ButtonGroup style={{ float: "right" }}>
            <Button
              onClick={() => {
                if (onDiscard) onDiscard();
                setEditState(dataset);
              }}
              variant="contained"
              color="secondary"
            >
              Discard
            </Button>
            <Button
              onClick={() => {
                if (onSave) onSave(editState);
              }}
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          </ButtonGroup>
        </div>
      </Stack>
    </HoverCard>
  );
};

export default EditDatasetCard;
