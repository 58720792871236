import { SvgIcon } from "@mui/material";
import React from "react";

const InstitutionIcon = React.forwardRef((props: any, ref) => (
  <SvgIcon {...props} viewBox="0 0 103.08 122.88" ref={ref}>
    <path d="M49.35,35.5a1.67,1.67,0,1,1,3.34,0V42h4.79a1.68,1.68,0,0,1,0,3.35H51a1.67,1.67,0,0,1-1.67-1.67V35.5ZM53.43,3V19.74l26.12,15c2.44,1.58,2.14.89,2.14,3.76V52.65h15A6.41,6.41,0,0,1,103.08,59v63.85H67.69V80.37c0-8.34-4.19-13.72-9.56-16.1a16.3,16.3,0,0,0-13.18,0c-5.37,2.38-9.56,7.75-9.56,16.1v42.51H0V59a6.4,6.4,0,0,1,6.39-6.38h15V38.37c0-2.82-.18-2.12,2.28-3.66l26.88-15V3a2,2,0,0,1-.41-1.14A1.84,1.84,0,1,1,53.43,3Zm1.39.71C64.47-3,65.19,10.2,74.66,2V15.4c-9,8.12-11-5-19.84,1.67V3.67Zm.92,48.65a11.38,11.38,0,1,0-9-.25,11.39,11.39,0,0,0,9,.25Zm7.67,70.56H39.67V80.37c0-6.4,3.07-10.46,7-12.21a12.22,12.22,0,0,1,9.74,0c3.93,1.75,7,5.81,7,12.21v42.51Zm12-15.11h7.08V114H75.4v-6.24ZM86.88,88.19H94V82H86.88v6.24Zm0-12.91H94V69H86.88v6.24ZM75.4,88.19h7.08V82H75.4v6.24Zm0-12.91h7.08V69H75.4v6.24ZM86.88,94.86H94v6.24H86.88V94.86Zm0,12.91H94V114H86.88v-6.24ZM75.4,94.86h7.08v6.24H75.4V94.86ZM9.13,107.77H16.2V114H9.13v-6.24ZM20.6,88.19h7.08V82H20.6v6.24Zm0-12.91h7.08V69H20.6v6.24ZM9.13,88.19H16.2V82H9.13v6.24Zm0-12.91H16.2V69H9.13v6.24ZM20.6,94.86h7.08v6.24H20.6V94.86Zm0,12.91h7.08V114H20.6v-6.24ZM9.13,94.86H16.2v6.24H9.13V94.86Z" />
  </SvgIcon>
));

export default InstitutionIcon;
