import { CardContent, Chip, Grid, Tooltip, Typography } from "@mui/material";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import HoverCard from "../HoverCard";
import { makeStyles } from "@mui/styles";
import { ViewColumn } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { IDataset } from "../../services/interfaces/IDataset";
import ContactGroup from "../ContactGroup";
import InstitutionIcon from "../icons/InstitutionIcon";
import { useEffect, useState } from "react";
import DatasetService from "../../services/DatasetService";
import { IconButton } from "@mui/material";

import { useSnackbar } from "notistack";
import DeleteConfirmation from "../DeleteConfirmation";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import EditDatasetCard from "./EditDatasetCard";

interface IDatasetCard {
  dataset: IDataset;
  productOwners?: string[];
  expanded?: boolean;
  editable?: boolean;
}

const useStyles = makeStyles({
  root: {
    position: "relative",
  },
  bottomBorder: {
    borderBottom: "1px solid RGB(211, 211, 211)",
  },
  rightBorder: {
    borderRight: "1px solid RGB(211,211,211)",
  },
  link: {
    textDecoration: "none",
    color: "black",
    fontWeight: 900,
    cursor: "pointer",
    "&:hover": {
      color: "blue",
      textDecoration: "underline",
    },
  },
  heavyWeight: {
    fontWeight: 900,
  },
  productButton: {
    color: "black",
    borderColor: "black",
    textTransform: "lowercase",
  },
  editButton: {
    position: "absolute",
    top: "5px",
    right: "5px",
  },
});

const DatasetCard = ({
  dataset,
  productOwners,
  expanded,
  editable,
}: IDatasetCard) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [authorized, setAuthorized] = useState(false);
  const [isEditing, setIsEditing] = useState(dataset.id <= 0);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (editable && dataset.id > 0) {
      DatasetService.isAuthorized(dataset.id).then(setAuthorized);
    } else if (dataset.id <= 0) {
      setAuthorized(true);
    }
  }, [dataset.id, editable]);

  const canEdit = editable && authorized;

  const onDelete = () =>
    DatasetService.deleteDataset(dataset.id).then((isDeleted) => {
      if (isDeleted) {
        enqueueSnackbar("Dataset Deleted", { variant: "success" });
        navigate(`/products/${dataset.productId}`);
      } else {
        enqueueSnackbar("Failed to Delete Dataset.", { variant: "error" });
      }
    });

  if (isEditing)
    return (
      <EditDatasetCard
        dataset={dataset}
        onDiscard={() => {
          if (dataset.id > 0) setIsEditing(false);
          // existing dataset
          else navigate(`/products/${dataset.productId}`);
        }}
        onSave={(editState) => {
          if (dataset.id) {
            DatasetService.putDataset(editState).then((ds) => {
              enqueueSnackbar("Dataset Updated.", { variant: "success" });
              navigate(0);
            });
          } else {
            DatasetService.postDataset(editState).then((ds) => {
              enqueueSnackbar("Dataset Created.", { variant: "success" });
              navigate(`/products/${ds.productId}/datasets/${ds.id}`);
            });
          }
        }}
      />
    );

  const buttons = (
    <div className={classes.editButton}>
      <Tooltip title="Edit">
        <IconButton
          color="primary"
          size="small"
          onClick={() => setIsEditing(true)}
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete">
        <IconButton
          color="secondary"
          size="small"
          onClick={() => setDeleteConfirmationOpen(true)}
        >
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </div>
  );

  const datasetName = (
    <Link
      to={`/products/${dataset.productId}/datasets/${dataset.id}`}
      className={classes.link}
    >
      <Typography variant="h5">{dataset.name}</Typography>
    </Link>
  );

  const datasetDescription = (
    <div style={{ maxHeight: expanded ? "auto" : 200, overflow: "clip" }}>
      <ReactMarkdown
        unwrapDisallowed
        disallowedElements={["h1", "h2", "h3", "h4", "h5", "h6"]}
        remarkPlugins={[remarkGfm]}
      >
        {dataset.description}
      </ReactMarkdown>
    </div>
  );

  const tags = dataset.tags.map((tag, i) => (
    <a
      key={i}
      href={`/search?filter.tag=${tag.toLowerCase()}`}
      className={classes.link}
      style={{ margin: 2 }}
    >
      <Chip
        label={tag}
        variant="outlined"
        style={{ color: "inherit", cursor: "pointer" }}
      />
    </a>
  ));

  const updateFrequency = <Typography>{dataset.updateFrequency}</Typography>;

  const fileType = <Typography>{dataset.fileType}</Typography>;

  const locationPath = (
    <Typography style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
      {dataset.locationPath}
    </Typography>
  );

  const owners = (
    <div>
      {productOwners && (
        <ContactGroup
          emails={productOwners}
          tracker={{ datasetId: dataset.id, datasetName: dataset.name }}
          cannedMessage={`Hi I am interested in the dataset ${dataset.name} that is produced by your product ${dataset.productName}.`}
        />
      )}
      <ContactGroup
        emails={dataset.owners.filter(
          (x) => (productOwners || []).findIndex((y) => x === y) === -1
        )} // remove product owners from dataset owners
        tracker={{ datasetId: dataset.id, datasetName: dataset.name }}
        cannedMessage={`Hi I am interested in the dataset ${dataset.name} that is produced by your product ${dataset.productName}.`}
      />
    </div>
  );

  return (
    <HoverCard square>
      <CardContent className={classes.root}>
        <Grid container spacing={1}>
          <Grid
            item
            md={8}
            className={`${classes.bottomBorder} ${classes.rightBorder}`}
          >
            {datasetName}
            {!isEditing && (
              <Link
                to={`/products/${dataset.productId}`}
                className={classes.link}
              >
                <Typography variant="h6">{dataset.productName}</Typography>
              </Link>
            )}
            {datasetDescription}
          </Grid>
          <Grid item md={4} className={classes.bottomBorder}>
            <Typography className={classes.heavyWeight}>Tags</Typography>
            {tags}
          </Grid>
          <Grid
            container
            item
            md={8}
            rowSpacing={1}
            className={`${
              dataset.owners?.length !== 0 ? classes.bottomBorder : ""
            } ${classes.rightBorder}`}
          >
            <Grid item md={6}>
              <Typography className={classes.heavyWeight}>
                Frequency Of Update
              </Typography>
              {updateFrequency}
            </Grid>
            <Grid item md={6}>
              <Typography className={classes.heavyWeight}>File Type</Typography>
              {fileType}
            </Grid>
            <Grid item md={12}>
              <Typography className={classes.heavyWeight}>
                Location Path
              </Typography>
              {locationPath}
            </Grid>
          </Grid>
          <Grid
            container
            item
            md={4}
            className={
              dataset.owners?.length !== 0 ? classes.bottomBorder : undefined
            }
          >
            <Grid item md={6} justifyContent="center" alignContent="center">
              <Tooltip title="No. of Columns" placement="top" arrow>
                <ViewColumn fontSize="large" />
              </Tooltip>
              <Typography>{dataset.columns.length || "NA"}</Typography>
            </Grid>
            <Grid item md={6}>
              <Tooltip title="No. of Institutions" placement="top" arrow>
                <InstitutionIcon fontSize="large" />
              </Tooltip>
              <Typography>{dataset?.institutionIds?.length || "NA"}</Typography>
            </Grid>
          </Grid>
          <Grid item md={12}>
            <Typography className={classes.heavyWeight}>Owners</Typography>
            {owners}
          </Grid>
        </Grid>
        {canEdit && buttons}
      </CardContent>
      <DeleteConfirmation
        open={deleteConfirmationOpen}
        onClose={() => setDeleteConfirmationOpen(false)}
        onDelete={onDelete}
        message="Are you sure you want to delete this dataset?"
      />
    </HoverCard>
  );
};

export default DatasetCard;
