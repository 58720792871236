// import { PublicClientApplication } from "@azure/msal-browser";
// import { MsalProvider } from "@azure/msal-react";
import { ThemeProvider } from "@emotion/react";
import { createTheme, CssBaseline } from "@mui/material";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import AuthConfig from "./config/AuthConfig";
import reportWebVitals from "./reportWebVitals";
import { Providers } from "@microsoft/mgt-element";
import { Msal2Provider } from "@microsoft/mgt-msal2-provider";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./config/InsightConfig";
import { BrowserRouter as Router } from "react-router-dom";
import { SnackbarProvider } from "notistack";

// initialize the auth provider globally
Providers.globalProvider = new Msal2Provider(AuthConfig);

const theme = createTheme({});

ReactDOM.render(
  <React.StrictMode>
    <AppInsightsContext.Provider value={reactPlugin}>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={5}>
          <Router>
            <App />
          </Router>
        </SnackbarProvider>
      </ThemeProvider>
    </AppInsightsContext.Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
