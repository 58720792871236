import {
  Button,
  Grid,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { DispatchContext, StateContext } from "../SearchPage";
import SearchIcon from "@mui/icons-material/Search";

const TopFilterPanel = () => {
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);
  const [anchor, setAnchor] = useState<Element | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>(state.search);

  useEffect(() => {
    const id = setTimeout(() => {
      dispatch({ type: "searchUpdate", value: searchTerm || "" });
    }, 500);
    return () => {
      clearTimeout(id);
    };
  }, [searchTerm, dispatch]);

  return (
    <div style={{ padding: "0px 15px 10px 15px" }}>
      <TextField
        fullWidth
        placeholder="Search..."
        value={searchTerm}
        onChange={(e) => {
          setSearchTerm(e.target.value);
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <Grid
        container
        style={{
          background: "#000000",
          marginTop: "10px",
          padding: "15px 25px 15px 25px",
        }}
      >
        <Grid item xs={10}>
          <Typography variant="h6" style={{ color: "#FFFFFF" }}>
            {`${state.totalItems} Datasets Found`}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="outlined"
            style={{ color: "#FFFFFF", borderColor: "#FFFFFF", float: "right" }}
            onClick={(e) => setAnchor(e.currentTarget)}
          >
            Sort By
          </Button>
          <Menu
            open={anchor !== null}
            anchorEl={anchor}
            onClose={() => setAnchor(null)}
          >
            <MenuItem
              onClick={() => dispatch({ type: "sortByUpdate", key: "Product" })}
            >
              Product
            </MenuItem>
            <MenuItem
              onClick={() => dispatch({ type: "sortByUpdate", key: "Name" })}
            >
              Dataset Name
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>
    </div>
  );
};

export default TopFilterPanel;
