import { Grid } from "@mui/material";
import AttributeFilter from "./AttributeFilter";

const SideFilterPanel = () => {
  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <AttributeFilter title="tag" filterKey="tag" searchKey="Tags" />
      </Grid>
      <Grid item>
        <AttributeFilter
          title="product"
          filterKey="productName"
          searchKey="ProductName"
          displayAll
        />
      </Grid>
      <Grid item>
        <AttributeFilter title="dataset" filterKey="dataset" searchKey="Name" />
      </Grid>
      <Grid item>
        <AttributeFilter
          title="column"
          filterKey="column"
          searchKey="Columns/Name"
        />
      </Grid>
    </Grid>
  );
};

export default SideFilterPanel;
