import { ChatBubbleOutlined } from "@mui/icons-material";
import GroupIcon from "@mui/icons-material/Group";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SpeedDial,
  SpeedDialAction,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useCallback, useState } from "react";
import FeedbackService from "../services/FeedbackService";
import SendIcon from "./icons/SendIcon";

const Team_Contacts = process.env.REACT_APP_TEAM_CONTACT;

const AppSpeedDial = () => {
  const [contactTeamModalOpen, setContactTeamModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const handleModalClose = useCallback(() => {
    setContactTeamModalOpen(false);
    setModalMessage("");
  }, []);
  const handleSend = useCallback(() => {
    FeedbackService.sendFeedback(modalMessage).then((success) => {
      if (success) {
        enqueueSnackbar("Feedback has been received!", { variant: "success" });
        handleModalClose();
      } else {
        enqueueSnackbar("Feedback wasn't received.", { variant: "error" });
      }
    });
  }, [modalMessage, handleModalClose, enqueueSnackbar]);
  return (
    <>
      <SpeedDial
        style={{
          position: "fixed",
          bottom: "25px",
          left: "25px",
        }}
        icon={<SendIcon />}
        ariaLabel="Provide contact"
      >
        <SpeedDialAction
          icon={<ChatBubbleOutlined />}
          tooltipTitle="Give Feedback"
          onClick={() => {
            // @ts-ignore
            if (window.Appcues) {
              // @ts-ignore
              window.Appcues.show("495043f1-a4c3-4b52-9f9e-1a6f8db9af75");
            }
          }}
        />
        <SpeedDialAction
          icon={<GroupIcon />}
          tooltipTitle="Contact the team"
          onClick={() => {
            setContactTeamModalOpen(true);
            // window.open(
            //   `https://teams.microsoft.com/l/chat/0/0?users=${Team_Contacts}&topicName=Contact Data Discovery Team`,
            //   "_blank"
            // );
          }}
        />
      </SpeedDial>
      <Dialog
        open={contactTeamModalOpen}
        onClose={handleModalClose}
        aria-labelledby="contact-dialog-title"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="contact-dialog-title">
          Contact the Data Discovery Team
        </DialogTitle>
        <DialogContent>
          <TextField
            placeholder="Send the team a message"
            value={modalMessage}
            onChange={(e) => setModalMessage(e.target.value)}
            rows={5}
            minRows={5}
            fullWidth
            multiline
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose}>Close</Button>
          <Button onClick={handleSend} autoFocus>
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AppSpeedDial;
