import {
  Button,
  ButtonGroup,
  CircularProgress,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import Colors from "../../config/Colors";
import RegexConfig from "../../config/RegexConfig";
import { IProduct } from "../../services/interfaces/IProduct";
import ProductService from "../../services/ProductService";
import HoverCard from "../HoverCard";
import ValidatedTextField from "../ValidatedTextField";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";

interface IProductTitle {
  value: string;
  setValue: (v: string) => void;
}

const EditProductTitle = ({ value, setValue }: IProductTitle) => {
  const originalValue = useRef(value);
  const [isNameOk, setIsNameOk] = useState<boolean>(true);
  const [checkingName, setCheckingName] = useState(false);

  useEffect(() => {
    if (value === "") {
      setIsNameOk(false);
    } else {
      const tid = setTimeout(() => {
        if (value === originalValue.current) {
          setIsNameOk(true);
        } else {
          setCheckingName(true);
          ProductService.checkProductName(value)
            .then(setIsNameOk)
            .finally(() => setCheckingName(false));
        }
      }, 500);
      return () => clearTimeout(tid);
    }
  }, [value]);

  return (
    <TextField
      error={!isNameOk}
      fullWidth
      size="small"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      label="Product Name"
      required
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {checkingName ? (
              <CircularProgress size={15} />
            ) : isNameOk ? (
              <CheckIcon htmlColor={Colors.Green} />
            ) : (
              <Tooltip title="Product name must be set and be unique.">
                <ErrorIcon htmlColor={Colors.Red} />
              </Tooltip>
            )}
          </InputAdornment>
        ),
      }}
    />
  );
};

interface IEditingProductCard {
  product: IProduct;
  onDiscard?: () => void;
  onSave?: (editState: IProduct) => void;
}

const EditingProductCard = ({
  product,
  onDiscard,
  onSave,
}: IEditingProductCard) => {
  const [editState, setEditState] = useState(product);

  return (
    <HoverCard>
      <Stack padding={3} spacing={3}>
        <EditProductTitle
          value={editState.name}
          setValue={(v) => setEditState({ ...editState, name: v })}
        />

        <ValidatedTextField
          fullWidth
          value={editState.description}
          onChange={(e) =>
            setEditState({ ...editState, description: e.target.value })
          }
          label="Product Description"
          multiline
          required
          rows={15}
          isError={(value) => {
            if (value === "") {
              return "Description cannot be empty.";
            }
          }}
        />

        <ValidatedTextField
          label="Owners"
          fullWidth
          value={editState.owners.join(",")}
          onChange={(e) =>
            setEditState({
              ...editState,
              owners: e.target.value.split(","),
            })
          }
          required
          helperText="Comma separated list of email addresses."
          isError={(value: string) => {
            if (value === "")
              return "At least one owner email address must be provided.";
            const emails = value
              .split(",")
              .map((x) => x.trim())
              .filter((x) => x !== "");
            // all are emails
            if (
              !emails.every((x) =>
                RegexConfig.emailReg.test(x.toLocaleLowerCase())
              )
            ) {
              return "All entries must be valid email addresses.";
            }
          }}
        />
        <div style={{ width: "100%" }}>
          <ButtonGroup style={{ float: "right" }}>
            <Button
              onClick={() => {
                if (onDiscard) onDiscard();
                setEditState(product);
              }}
              variant="contained"
              color="secondary"
            >
              Discard
            </Button>
            <Button
              onClick={() => {
                if (onSave) onSave(editState);
              }}
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          </ButtonGroup>
        </div>
      </Stack>
    </HoverCard>
  );
};

export default EditingProductCard;
