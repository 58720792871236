import { TextField, TextFieldProps } from "@mui/material";
import { useEffect, useRef, useState } from "react";

interface IValidatedTextField {
  isError: (value?: any) => string | undefined;
}

const ValidatedTextField = ({
  isError,
  ...props
}: IValidatedTextField & TextFieldProps) => {
  const showErrors = useRef(false);
  const [error, setError] = useState(false);
  const [reason, setReason] = useState<string>();
  useEffect(() => {
    const reason = isError(props.value);
    setError(reason !== undefined);
    setReason(reason);
  }, [props.value, isError]);
  return (
    <TextField
      {...props}
      error={showErrors.current && error}
      helperText={
        showErrors.current ? reason || props.helperText : props.helperText
      }
      onChange={(e) => {
        if (props.onChange) props.onChange(e);
        showErrors.current = true;
      }}
    />
  );
};

export default ValidatedTextField;
