import { FunctionAppFetch } from "./Fetch";
import { IInstitution } from "./interfaces/IInstitution";
import { IProduct } from "./interfaces/IProduct";

const getProducts = (): Promise<IProduct[]> =>
  FunctionAppFetch.get("/api/products").then((response) => response.json());

const getProduct = (id: number): Promise<IProduct> =>
  FunctionAppFetch.get(`/api/products/${id}`).then((response) =>
    response.json()
  );
const postProduct = (product: IProduct): Promise<IProduct> =>
  FunctionAppFetch.post("/api/products", product).then((response) =>
    response.json()
  );
const putProduct = (product: IProduct): Promise<IProduct> =>
  FunctionAppFetch.put(`/api/products/${product.id}`, product).then(
    (response) => response.json()
  );

const isAuthorized = (productId: number): Promise<boolean> =>
  FunctionAppFetch.get(`/api/products/${productId}/authorized`).then(
    (response) => response.json()
  );

const deleteProduct = (productId: number): Promise<boolean> =>
  FunctionAppFetch.delete(`/api/products/${productId}`).then(
    (response) => response.status === 200
  );

const checkProductName = (name: string): Promise<boolean> =>
  FunctionAppFetch.get(`/api/products:nameCheck?name=${name}`).then(
    (response) => response.json()
  );

const getProductInstitutions = (productId: number): Promise<IInstitution[]> =>
  FunctionAppFetch.get(`/api/products/${productId}/institutions`).then(
    (response) => response.json()
  );

const ProductService = {
  getProducts,
  getProduct,
  postProduct,
  putProduct,
  isAuthorized,
  deleteProduct,
  checkProductName,
  getProductInstitutions,
};

export default ProductService;
