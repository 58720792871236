import { Route, Routes } from "react-router";
import DatasetRoutes from "../dataset/DatasetRoutes";
import ProductCreatePage from "./ProductCreatePage";
import ProductPage from "./ProductPage";
import ProductsPage from "./ProductsPage";

const ProductRoutes = () => {
  return (
    <div style={{ display: "flex", margin: "0 65px 0 65px", width: "100%" }}>
      <Routes>
        <Route path=":productId/datasets/*" element={<DatasetRoutes />} />
        <Route path="new" element={<ProductCreatePage />} />
        <Route path=":id" element={<ProductPage />} />
        <Route path="" element={<ProductsPage />} />
      </Routes>
    </div>
  );
};

export default ProductRoutes;
