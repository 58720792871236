import { CardContent, Chip, Grid, TableRow } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import HoverCard from "../../components/HoverCard";
import Loader from "../../components/Loader";
import ProductCard from "../../components/Product/ProductCard";
import DatasetService from "../../services/DatasetService";
import { IDataset } from "../../services/interfaces/IDataset";
import { IProduct } from "../../services/interfaces/IProduct";
import ProductService from "../../services/ProductService";
import { Link } from "react-router-dom";
import DataTable from "../../components/DataTable";
import { TableCell } from "@mui/material";

interface IDatasetTableCard {
  productId: number;
  datasets: IDataset[];
  authorized: boolean;
}

const DatasetTableCard = ({
  productId,
  datasets,
  authorized,
}: IDatasetTableCard) => {
  const navigate = useNavigate();
  const data = useMemo(
    () =>
      datasets.map((x) => ({
        id: x.id,
        name: x.name,
        numberOfColumns: x.columns?.length,
        numberOfInstitutions: x.institutionIds?.length,
        tags: x.tags,
      })),
    [datasets]
  );
  return (
    <HoverCard>
      <CardContent>
        <DataTable
          title="Datasets"
          header={[
            { name: "Name", field: "name", sortable: true },
            {
              name: "Number of Columns",
              field: "numberOfColumns",
              sortable: true,
            },
            {
              name: "Number of Institutions",
              field: "numberOfInstitutions",
              sortable: true,
            },
            { name: "Tags" },
          ]}
          data={data}
          mapper={(row) => (
            <TableRow key={row.id}>
              <TableCell>
                <Link to={`/products/${productId}/datasets/${row.id}`}>
                  {row.name}
                </Link>
              </TableCell>
              <TableCell>{row.numberOfColumns}</TableCell>
              <TableCell>{row.numberOfInstitutions || "NA"}</TableCell>
              <TableCell>
                {row.tags?.map((tag: any, i: number) => (
                  <Chip
                    key={i}
                    label={tag}
                    style={{ marginRight: "2px" }}
                    variant="outlined"
                  />
                ))}
              </TableCell>
            </TableRow>
          )}
          searchFunc={(term, data) =>
            data.filter((x) =>
              x.name.toLowerCase().includes(term.toLowerCase())
            )
          }
          addFunc={
            authorized
              ? () => navigate(`/products/${productId}/datasets/new`)
              : undefined
          }
        />
      </CardContent>
    </HoverCard>
  );
};

const ProductPage = () => {
  const params = useParams();
  const [product, setProduct] = useState<IProduct>();
  const [datasets, setDatasets] = useState<IDataset[]>();
  const [authorized, setAuthorized] = useState(false);

  // @ts-ignore
  const id = params?.id as number;

  useEffect(() => {
    ProductService.isAuthorized(id).then(setAuthorized);
  }, [id]);

  useEffect(() => {
    if (!product && id) {
      ProductService.getProduct(id).then(setProduct);
    }
  }, [id, product, datasets]);

  useEffect(() => {
    if (!datasets && id) {
      DatasetService.getProductDatasets(id).then(setDatasets);
    }
  }, [id, datasets]);

  if (!product) return <Loader />;

  let productInstitutionIds = [] as number[];
  if (datasets) {
    const s = new Set(datasets.flatMap((x) => x.institutionIds ?? []));
    productInstitutionIds = Array.from(s);
  }

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <ProductCard
          {...product}
          institutionIds={productInstitutionIds}
          editable
        />
      </Grid>
      {datasets && (
        <Grid item>
          <DatasetTableCard
            datasets={datasets}
            productId={id}
            authorized={authorized}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default ProductPage;
