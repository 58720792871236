import { LoginType } from "@microsoft/mgt-element";
import { Msal2Config } from "@microsoft/mgt-msal2-provider";

const AuthConfig: Msal2Config = {
  clientId: process.env.REACT_APP_CLIENT_ID as string,
  authority: `https://login.microsoftonline.com/${process.env.REACT_APP_TENANT_ID}`,
  redirectUri: "/",
  loginType: LoginType.Redirect,
};

export default AuthConfig;
