import {
  IProviderAccount,
  Providers,
  ProviderState,
} from "@microsoft/mgt-element";
import { useEffect, useState } from "react";

const useAuthenticatedUser = () => {
  const [user, setUser] = useState<IProviderAccount>();

  useEffect(() => {
    const updateState = () => {
      const provider = Providers.globalProvider;
      if (
        provider &&
        provider.state === ProviderState.SignedIn &&
        provider.getAllAccounts
      ) {
        const accounts = provider.getAllAccounts();
        setUser(accounts[0]);
      }
    };

    Providers.onProviderUpdated(updateState);
    updateState();

    return () => {
      Providers.removeProviderUpdatedListener(updateState);
    };
  }, []);

  return user;
};

export default useAuthenticatedUser;
