import { Grid, LinearProgress, Typography } from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import RippleLoader from "../../../components/RippleLoader";
import usePrevious from "../../../hooks/usePrevious";
import SearchService from "../../../services/SearchService";
import { DispatchContext, StateContext } from "../SearchPage";
import ResultCard from "./ResultCard";

const ResultsPanel = () => {
  const [page, setPage] = useState(-2); // -1 to avoid first call when setting up useEffect
  const [loading, setLoading] = useState(false);
  const state = useContext(StateContext);
  const dispatch = useContext(DispatchContext);
  const prevPage = usePrevious(page);

  const PAGE_SIZE = 10;

  const fetchResults = useCallback(
    (page: number) => {
      if (page === 0) setLoading(true);
      SearchService.SearchDatasets({
        term: state.search,
        products: state.filters["productName"] || [],
        tables: state.filters["dataset"] || [],
        tags: state.filters["tag"] || [],
        columns: state.filters["column"] || [],
        facets: ["ProductName"],
        pageIndex: page,
        pageSize: PAGE_SIZE,
        sortBy: state.sortBy,
      })
        .then((json) => {
          dispatch({
            type: "updateResult",
            totalItems: json.totalResults,
            items:
              page !== 0 ? [...state.items, ...json.results] : json.results,
          });
        })
        .finally(() => setLoading(false));
    },
    [state, dispatch]
  );

  useEffect(() => {
    if (page >= 0 && page !== prevPage) {
      return fetchResults(page);
    }
    if (page < 0) setPage(0);
  }, [page, prevPage, fetchResults]);

  useEffect(() => {
    setPage(-1); // reset
  }, [state.search, state.filters, state.sortBy]);

  if (loading)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "200px",
        }}
      >
        <div style={{ height: 200, width: 200 }}>
          <RippleLoader />
        </div>
      </div>
    );

  return (
    <InfiniteScroll
      dataLength={state.items.length}
      next={() => setPage(page + 1)}
      hasMore={state.items.length < state.totalItems}
      loader={<LinearProgress />}
      endMessage={
        <Typography align="center">
          If you didn't find what you're looking for request it :)
        </Typography>
      }
    >
      <Grid
        container
        direction="column"
        spacing={3}
        style={{ marginBottom: "30px", padding: "0 15px" }}
      >
        {state.items.map((x) => (
          <Grid item key={x.id}>
            <ResultCard dataset={x} />
          </Grid>
        ))}
      </Grid>
    </InfiniteScroll>
  );
};

export default ResultsPanel;
