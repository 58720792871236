// AppInsights.js
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory } from "history";
import { Providers, ProviderState } from "@microsoft/mgt-element";

const browserHistory = createBrowserHistory();
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    connectionString: process.env.REACT_APP_INSTRUMENTATION_CONNECTION_STRING,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});

const authenticationEvent = () => {
  appInsights.clearAuthenticatedUserContext();
  const provider = Providers.globalProvider;
  if (
    provider &&
    provider.state === ProviderState.SignedIn &&
    provider.getAllAccounts
  ) {
    const accounts = provider.getAllAccounts();
    const account = accounts[0];
    appInsights.setAuthenticatedUserContext(
      account.username || account.id,
      account.id
    );
  }
};

Providers.onProviderUpdated(authenticationEvent);

appInsights.loadAppInsights();
export { reactPlugin, appInsights };
