import { SvgIcon } from "@mui/material";
import React from "react";

const SendIcon = React.forwardRef((props: any, ref) => (
  <SvgIcon {...props} viewBox="0 0 122.56 122.88" ref={ref}>
    <path d="M112.27,10.31l-99,38.07,30,14.37L89.21,33.18,60.44,80.53l14,29.06,37.81-99.28ZM2.42,44.49,117.16.37a3.73,3.73,0,0,1,3-.12,3.78,3.78,0,0,1,2.19,4.87L78.4,120.45a3.78,3.78,0,0,1-6.92.3l-22.67-47L2.14,51.39a3.76,3.76,0,0,1,.28-6.9Z" />
  </SvgIcon>
));

export default SendIcon;
