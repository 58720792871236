import { Grid, Typography } from "@mui/material";
import { createContext, Dispatch, useEffect, useReducer } from "react";

import SideFilterPanel from "./components/SideFilterPanel";
import TopFilterPanel from "./components/TopFilterPanel";
import ResultsPanel from "./components/ResultsPanel";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { IDataset } from "../../services/interfaces/IDataset";

type SearchPageAction =
  | { type: "filterUpdate"; key: string; values: string[] }
  | { type: "searchUpdate"; value: string }
  | { type: "sortByUpdate"; key: string }
  | { type: "updateResult"; totalItems: number; items: IDataset[] };

export interface ISearchPageState {
  search: string;
  filters: { [key: string]: string[] };
  sortBy: string;
  items: IDataset[];
  totalItems: number;
}

const reducer = (state: ISearchPageState, action: SearchPageAction) => {
  switch (action.type) {
    case "filterUpdate":
      return {
        ...state,
        filters: { ...state.filters, [action.key]: action.values },
      };
    case "searchUpdate":
      return {
        ...state,
        search: action.value,
      };
    case "sortByUpdate":
      return {
        ...state,
        sortBy: action.key,
      };
    case "updateResult":
      return {
        ...state,
        totalItems: action.totalItems,
        items: action.items,
      };
    default:
      return state;
  }
};

const initialState = {
  search: "",
  filters: {},
  sortBy: "Name",
  items: [],
  totalItems: 0,
  token: "",
};

//@ts-ignore
export const DispatchContext = createContext<Dispatch<SearchPageAction>>();
export const StateContext = createContext<ISearchPageState>(initialState);

const SearchPage = () => {
  const [state, dispatch] = useReducer(reducer, undefined, (arg) => {
    const url = new URL(window.location.href);
    const params = url.searchParams;
    const filters = {} as { [key: string]: string[] };
    params.forEach((v, k) => {
      if (k.startsWith("filter.")) {
        filters[k.replace("filter.", "")] = v.split("|");
      }
    });
    return {
      ...initialState,
      search: params.get("search") || initialState.search,
      sortBy: params.get("sortBy") || initialState.sortBy,
      filters: filters,
    };
  });
  const appInsights = useAppInsightsContext();
  useEffect(() => {
    // set url from state
    const urlParams = new URLSearchParams();
    if (state.search) {
      urlParams.append("search", state.search);
    }
    if (state.filters) {
      for (const [key, value] of Object.entries(state.filters)) {
        if (value?.length !== 0) {
          urlParams.append(`filter.${key}`, value.join("|"));
        }
      }
    }
    if (state.sortBy) {
      urlParams.append("sortBy", state.sortBy);
    }
    window.history.replaceState(null, "", `/search?${urlParams.toString()}`);
    appInsights.trackEvent(
      { name: "SearchChange" },
      {
        ...state.filters,
        search: state.search,
        sortBy: state.sortBy,
      }
    );
  }, [state.filters, state.search, state.sortBy, appInsights]);
  return (
    <DispatchContext.Provider value={dispatch}>
      <StateContext.Provider value={state}>
        <Grid container direction="column" spacing={3}>
          <Grid item xs={"auto"}>
            <Typography variant="h3" variantMapping={{ h3: "h1" }}>
              Anthology Data Discovery
            </Typography>
            <Typography variant="h6" variantMapping={{ h6: "h2" }}>
              Search Anthology Data Hub
            </Typography>
          </Grid>
          <Grid item container direction="row" columnSpacing={{ xs: 4 }}>
            <Grid item md={3}>
              <SideFilterPanel />
            </Grid>
            <Grid item md={9}>
              <TopFilterPanel />
              <ResultsPanel />
            </Grid>
          </Grid>
        </Grid>
      </StateContext.Provider>
    </DispatchContext.Provider>
  );
};

export default SearchPage;
