import { Button, Toolbar, Typography } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import { Person, Providers } from "@microsoft/mgt-react";
import useAuthenticated from "../hooks/useAuthenticated";
import { NavLink } from "react-router-dom";

interface INavigationLink {
  to: string;
  name: string;
}

const NavigationLink = ({ to, name }: INavigationLink) => (
  <NavLink
    to={to}
    style={({ isActive }) => ({
      ...{
        textDecoration: "none",
        color: "#ffffff",
        marginRight: "25px",
        display: "inline-flex",
        verticalAlign: "middle",
      },
      ...(isActive ? { borderBottom: "2px solid #ffffff" } : {}),
    })}
  >
    <Typography
      variant="h6"
      component="div"
      style={{
        display: "flex",
        verticalAlign: "middle",
        lineHeight: "55px",
      }}
    >
      {name}
    </Typography>
  </NavLink>
);

const TopBar = () => {
  const isSignedIn = useAuthenticated();
  const logout = async () => {
    if (Providers.globalProvider.logout) {
      await Providers.globalProvider.logout();
    }
  };
  const login = async () => {
    if (Providers.globalProvider.login) {
      await Providers.globalProvider.login();
    }
  };

  return (
    <AppBar
      position="static"
      style={{ marginBottom: 30, background: "#000000" }}
    >
      <Toolbar>
        <div style={{ flexGrow: 1 }}>
          <div
            style={{
              display: "inline-flex",
              verticalAlign: "middle",
            }}
          >
            <img
              src="/Anthology_Logo.svg"
              alt="Anthology Logo"
              height="55px"
              width="auto"
            />
          </div>
          <NavigationLink to="/search" name="Data Discovery" />
          <NavigationLink to="/products" name="Products" />
          <NavigationLink to="/instructions" name="Instructions" />
        </div>
        {isSignedIn ? (
          <Button color="inherit" onClick={logout}>
            <Person personQuery="me" />
            <div style={{ marginLeft: "5px", color: "inherit" }}>Logout</div>
          </Button>
        ) : (
          <Button color="inherit" onClick={login}>
            Login
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
